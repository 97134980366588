import { GET_DASHBOARD_REQUEST, GET_DASHBOARD_SUCCESS } from "./type";

const initialState = {
    DashboardData: null,
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_REQUEST:
            return {
                ...state,
                DashboardData: null,
            };
        case GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                DashboardData: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};
export default dashboardReducer