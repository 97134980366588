export const STORE_DETAILS_REQUEST = "STORE_DETAILS_REQUEST"
export const STORE_DETAILS_SUCCESS = "STORE_DETAILS_SUCCESS"
export const PROFILE_DETAILS_REQUEST = "PROFILE_DETAILS_REQUEST"
export const PROFILE_DETAILS_SUCCESS = "PROFILE_DETAILS_SUCCESS"
export const STORE_DETAIL_REQUEST = "STORE_DETAIL_REQUEST"
export const STORE_DETAIL_SUCCESS = "STORE_DETAIL_SUCCESS"
export const UPDATE_PROFILE_DETAILS_REQUEST = "UPDATE_PROFILE_DETAILS_REQUEST"
export const UPDATE_PROFILE_DETAILS_SUCCESS = "UPDATE_PROFILE_DETAILS_SUCCESS"
export const UPDATE_STORE_DETAILS_REQUEST = "UPDATE_STORE_DETAILS_REQUEST"
export const UPDATE_STORE_DETAILS_SUCCESS = "UPDATE_STORE_DETAILS_SUCCESS"
export const GET_LATEST_NOTIFICATION_REQUEST = "GET_LATEST_NOTIFICATION_REQUEST"
export const GET_LATEST_NOTIFICATION_SUCCESS = "GET_LATEST_NOTIFICATION_SUCCESS"
export const GET_READ_NOTIFICATION_REQUEST = "GET_READ_NOTIFICATION_REQUEST"
export const GET_READ_NOTIFICATION_SUCCESS = "GET_READ_NOTIFICATION_SUCCESS"