import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import {
  ApprovedSvg,
  DeleteSvg,
  DownArrowSvg,
  EditSvg,
  EditbtnSvg,
  EmailSvg2,
  LikeFillSvg,
  LikeSvg,
  LocationSvg,
  Phone_Svg,
  PluesSvg,
  PromotionsOptINSvg,
  ShopSvg,
  ShortDownSvg,
  ShortUpSvg,
  UserSvg,
  VendorSvg,
  ViewSvg,
} from "../../../components/CommonSvg";
import { Link, useNavigate } from "react-router-dom";
import TableHeader from "../../../components/Common/TableView/TableHeader";
import {
  deleteVendorRequest,
  getInterestedMemberListRequest,
  getVendorListRequest,
  removeVendorRequest,
} from "../../../redux/admin/vendor/action";
import CommonPagination from "../../../components/Common/TableView/CommonPagination";
import IntrestedMember from "./IntrestedMember";
import Nodata from "../../../assets/images/no_data_found001.webp";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import ReactSwitch from "react-switch";
import { Badge, Button, FormGroup, Input } from "reactstrap";
import AssignVendor from "./AssignVendor";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import { RoleWiseModule } from "../../../utils/generalUtils";
import { AdminAccess, StaffAccess } from "../../../constant/commonConstants";

function VendorList({
  getVendorList,
  vendorList,
  totalRecords,
  removeVendor,
  getInterestedMemberList,
  values,
  emailSetting,
  setEmailSettingData,
  deleteVendor,
  AssignVendorStatus,
}) {
  console.log(values, "valuesvalues");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [memberCount, setMemberCount] = useState({ id: "", count: 0 });
  const [orderBy, setOrderBy] = useState("DESC");
  const [orderKey, setOrderKey] = useState("createdDate");
  const dispatchData = {
    searchText: values.search,
    isActive: values.filter,
    stateId: values.stateId,
    cityId: values.cityId,
    countyId: values.countyId,
    isAssigned: values.assignedValue,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: orderKey,
      orderBy: orderBy,
    },
  };
  useEffect(() => {
    if (currentPage && dataPerPage) {
      getVendorList(dispatchData);
    }
  }, [
    currentPage,
    dataPerPage,
    values.search,
    values.filter,
    orderBy,
    values.stateId,
    values.cityId,
    values.countyId,
    values.assignedValue,
  ]);
  useEffect(() => {
    if (emailSetting) {
      setEmailSettingData(emailSetting);
    }
  }, [emailSetting]);

  useEffect(() => {
    if (values.clear === true) {
      getVendorList({
        searchText: "",
        pagination: {
          limit: dataPerPage,
          page: 1,
          orderKey: "createdDate",
          orderBy: "ASC",
        },
      });
      values.setClear(false);
    }
  }, [values.clear]);
  const onChangeOrderBy = (data) => {
    if (orderBy == "ASC") {
      setOrderBy("DESC");
      setOrderKey(data);
    } else {
      setOrderKey(data);
      setOrderBy("ASC");
    }
  };
  const [activeKey, setActiveKey] = useState("");
  const navigate = useNavigate();

  const handleItemClick = (data) => {
    navigate("/admin/vendor/view/" + data.id);
    // setActiveKey(index === activeKey ? null : index);
  };
  const [modalView, setModaleView] = useState(false);
  const [vendorId, setVendorId] = useState([]);
  const [editPromationData, setEditPromationData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [isAssigned, setIsAssigned] = useState("");
  console.log(isAssigned, "isAssignedisAssignedisAssigned");

  const onModalDismiss = () => {
    setModaleView(false);
    setVendorId([]);
    setEditPromationData([]);
    setVendorData([]);
  };
  const HandelSelectAssign = (data) => {
    if (data.isAssigned !== true) {
      console.log(data, "datadatadata");

      if (vendorId.includes(data.id)) {
        const updatedPromationData = vendorData.filter(
          (item) => item.id !== data.id
        );
        const updatedPromationId = vendorId.filter((id) => id !== data.id);
        setVendorData(updatedPromationData);
        setVendorId(updatedPromationId);
      } else {
        setVendorData([
          ...vendorData,
          {
            imgUrl: data.logo,
            id: data.id,
            title: data.name,
            description: data.address,
            assignUser: data.createdBy,
          },
        ]);
        setVendorId([...vendorId, data.id]);
      }
    }
  };
  const multipalAssignHandel = () => {
    if (vendorId) {
      setModaleView(true);
      setVendorId([...vendorId]);
      setVendorData([...vendorData]);
      setIsAssigned("");
    }
  };

  useEffect(() => {
    if (AssignVendorStatus === true) {
      setModaleView(false);
      setVendorId([]);
      setEditPromationData([]);
      setVendorData([]);
    }
  }, [AssignVendorStatus]);

  return (
    <>
      {vendorId?.length > 0 && (
        <Button
          color="primary"
          className="btn-sm mb-2 d-flex gap-1 align-items-center"
          type="button"
          onClick={
            RoleWiseModule(AdminAccess, StaffAccess) && multipalAssignHandel
          }
        >
          <svg
            style={{ width: "1.2rem", height: "1.2rem" }}
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="9"
              cy="5"
              r="4"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <circle
              cx="15"
              cy="15"
              r="4"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <path
              d="M13.6665 15L14.5 16L16.3332 14.1111"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 12.3271C11.0736 12.1162 10.0609 12 9 12C4.58172 12 1 14.0147 1 16.5C1 18.9853 1 21 9 21C14.6874 21 16.3315 19.9817 16.8068 18.5"
              stroke="currentColor"
              stroke-width="1.5"
            />
          </svg>
          Multiple Assign
        </Button>
      )}
      <Accordion
        activeKey={String(activeKey)}
        className="store_accordion vendor_accordion vendor-assign"
      >
        {vendorList.length > 0 ? (
          <>
            <ul className="vendor_member_row vendor_row_head member_row_head">
              <li className="vendor_name">
                <span>Vendor name</span>
                <Button className="btn" onClick={() => onChangeOrderBy("name")}>
                  {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
                </Button>
              </li>
              <li className="vendor_number">
                <span>Phone No.</span>
              </li>
              <li className="vendor_address">
                <span>Category</span>
              </li>
              <li className="vendor_zohoid">
                <span>Zoho Id</span>
              </li>
              <li className="vendor_doctype">
                <span>Type</span>
              </li>
              <li className="store-hra-action">
                <span></span>
              </li>
            </ul>
            {vendorList.map((data, index) => {
              return (
                <Accordion.Item eventKey={String(index)} key={index}>
                  <Accordion.Header
                    // onClick={() => {
                    //   handleAccordionSelect(data.id); // Toggle accordion state on click
                    // }}
                    className={activeKey === index ? "collapse-accordion" : ""}
                    onClick={() => handleItemClick(data)}
                  >
                    <ul>
                      <li
                        className="vendor_name"
                        onClick={(e) => {
                          if (e && e.stopPropagation) {
                            e.stopPropagation();
                          }
                        }}
                      >
                        {/* <span
                          title={
                            !data.isAssigned &&
                            vendorId.length === 0 &&
                            "Double-click for the multiple select items."
                          }
                          onDoubleClick={() => {
                            if (vendorId.length === 0) {
                              HandelSelectAssign(data);
                            }
                          }}
                          onClick={() => {
                            if (vendorId.length >= 1) {
                              HandelSelectAssign(data);
                            }
                          }}
                        >
                          Multi select
                        </span> */}
                        <Link to={"/admin/vendor/view/" + data.id}>
                          <img src={data.logo} width={40} className="me-2" />

                          {data.name}
                        </Link>
                      </li>
                      <li className="vendor_number">
                        <p>
                          <Phone_Svg />
                          {data.phoneNumber}
                        </p>
                      </li>
                      <li className="vendor_address">
                        <p>
                          {/* <Category /> */}
                          {data.category?.catName}
                        </p>
                      </li>
                      <li className="vendor_zohoid">
                        <p>
                          {/* <Category /> */}
                          {data.vendorZohoId}
                        </p>
                      </li>
                      <li className="vendor_doctype">
                        <span>
                          {data.htmlCreate
                            ? "HTML"
                            : data.pdfAttachment
                            ? "PDF"
                            : "URL"}
                        </span>
                      </li>
                    </ul>
                  </Accordion.Header>
                  <Accordion.Body>
                    {index === activeKey && (
                      <IntrestedMember
                        id={data.id}
                        setMemberCount={setMemberCount}
                      />
                    )}
                  </Accordion.Body>
                  <div className="vendor_check">
                    <FormGroup check>
                      <Input
                        type="checkbox"
                        id="multiSelectCheckbox"
                        checked={vendorId.includes(data.id) || data.isAssigned}
                        onChange={() => {
                          if (vendorId.length === 0 || vendorId.length >= 1) {
                            HandelSelectAssign(data);
                          }
                        }}
                        disabled={data.isAssigned}
                        // onDoubleClick={() => {
                        //   if (vendorId.length === 0) {
                        //     HandelSelectAssign(data);
                        //   }
                        // }}
                        // onClick={() => {
                        //   if (vendorId.length >= 1) {
                        //     HandelSelectAssign(data);
                        //   }
                        // }}
                      />
                    </FormGroup>
                  </div>
                  <div
                    className="vendor_action_button"
                    onClick={(e) => {
                      if (e && e.stopPropagation) {
                        e.stopPropagation();
                      }
                    }}
                  >
                    <div className="vendor-assign-status">
                      <Badge
                        pill
                        color={data.isAssigned ? "danger" : "primary"}
                        onClick={() => {
                          const havePromationId = vendorId.includes(data.id);
                          if (!havePromationId) {
                            setModaleView(true);
                            setVendorId([...vendorId, data.id]);
                            setIsAssigned(data.isAssigned);
                            setEditPromationData(data.AllocateVendors);
                            setVendorData([
                              ...vendorData,
                              {
                                imgUrl: data.documentPath,
                                id: data.id,
                                title: data.title,
                                assignUser: data.createdBy,
                              },
                            ]);
                          } else {
                            setModaleView(true);
                            setIsAssigned("");
                          }
                        }}
                      >
                        {data.isAssigned === true ? "Assigned" : "Assign"}
                      </Badge>
                    </div>
                    <Link
                      to={`/admin/vendor/edit/${data.id}`}
                      state={data}
                      data-tooltip-content={"Edit"}
                      className="text-info mx-2 my-tooltip"
                    >
                      <EditSvg />
                    </Link>
                    <Link
                      className="text-danger mx-2 my-tooltip"
                      data-tooltip-content={"Delete"}
                      onClick={async () => {
                        const response = await commonConfirmBox(
                          `Are you sure you want to delete this vendor?`
                        );
                        if (response) {
                          deleteVendor({
                            value: {
                              vendorId: data.id,
                            },
                            dispatchData,
                          });
                        }
                      }}
                    >
                      <DeleteSvg />
                    </Link>
                    <ReactSwitch
                      className={`react-switch justify-content-center switch-sm my-tooltip ${
                        data.isActive && "isActive"
                      }`}
                      data-tooltip-content={"Active/Deactive"}
                      onChange={async (e) => {
                        if (e && e.stopPropagation) {
                          e.stopPropagation();
                        }
                        const response = await commonConfirmBox(
                          data.isActive
                            ? `Are you sure you want to deactive this vendor?`
                            : `Are you sure you want to active this vendor?`
                        );
                        if (response) {
                          removeVendor({
                            value: {
                              vendorId: data.id,
                              status: !data.isActive,
                            },
                            dispatchData,
                          });
                        }
                      }}
                      type="checkbox"
                      id="custom-switch"
                      checked={data.isActive}
                    />
                    {/* </Link> */}
                    {/* <Link
                      className="view_btn_icon mx-2 my-tooltip"
                      data-tooltip-content={"View"}
                      to={"/admin/vendor/view/" + data.id}
                    >
                      <ViewSvg />
                    </Link> */}

                    <span className="like_btn">
                      <span className="fill_svg">
                        <LikeFillSvg />
                      </span>
                      <span className="line_svg">
                        <LikeSvg />
                      </span>
                      {data.id === memberCount?.id
                        ? memberCount.count
                        : data.distinctCount}
                    </span>
                    <Button
                      color="link"
                      className="view_substore"
                      onClick={(e) => {
                        if (e && e.stopPropagation) {
                          e.stopPropagation();
                        }
                        if (index === activeKey) {
                          setActiveKey("");
                        } else {
                          setActiveKey(index);
                        }
                      }}
                    >
                      <DownArrowSvg />
                    </Button>
                  </div>
                </Accordion.Item>
              );
            })}
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <img alt="NO Data Found" className="no_data_found" src={Nodata} />
          </div>
        )}
      </Accordion>
      {totalRecords > 0 && (
        <CommonPagination
          dataPerPage={dataPerPage}
          totalData={totalRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setDataPerPage={setDataPerPage}
        />
      )}
      {modalView && (
        <CustomisedModal
          modalView={modalView}
          customClass={
            vendorId.length > 1 ? "category-modal modal-lg" : "category-modal "
          }
          modalName={"Assign Vendor"}
          onModalDismiss={onModalDismiss}
        >
          <AssignVendor
            onModalDismiss={onModalDismiss}
            isAssigned={isAssigned}
            setPromationData={setVendorData}
            editPromationData={isAssigned === true ? editPromationData : []}
            setEditPromationData={setEditPromationData}
            promationData={vendorData}
            //   AssignPromotionStatus={AssignPromotionStatus}
            vendorId={vendorId}
            AssignVendorId={vendorId}
            dispatchData={dispatchData}
          />
        </CustomisedModal>
      )}
    </>
  );
}
const mapStateToProps = ({ vendor }) => {
  console.log(vendor, "vendorvendor");
  let vendorList =
    vendor?.getVendor?.list?.length > 0 ? vendor.getVendor.list : [];
  let totalRecords = vendor.getVendor?.totalRecords;
  let emailSetting = vendor?.getVendor?.emailSettingData
    ? vendor.getVendor.emailSettingData
    : null;
  let AssignVendorStatus = vendor.assign?.status && vendor.assign.status;
  return { vendorList, totalRecords, emailSetting, AssignVendorStatus };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorList: (data) => dispatch(getVendorListRequest(data)),
    removeVendor: (data) => dispatch(removeVendorRequest(data)),
    deleteVendor: (data) => dispatch(deleteVendorRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
