import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap';
import { getRoleListRequest } from '../../../redux/admin/role/action';
import { CloseSvg } from '../../../components/CommonSvg';
import ReactDatePicker from 'react-datepicker';
function UserFilter({ getRoleList, RoleListRequest, setExportFilterData, setRoleId, handelShowFIlter }) {
    useEffect(() => {
        RoleListRequest();
    }, []);
    const [selectedRole, setSelectedRole] = useState("")

    useEffect(() => {
        if (selectedRole === "") {
            setExportFilterData(false)
        }
    }, [selectedRole])
    return (
        <>
            <Card className="filter_section card">
                <CardBody>
                    <h6 className="separate-head">Filter</h6>
                    <>
                        <form className="hra-create">
                            <Row>
                                <Col sm={12} className="no-floating-label">
                                    <Row>
                                        <FormGroup className='col mb-0'>
                                            <Select
                                                type="text"
                                                id="Role"
                                                name="Role"
                                                className="form-control selectbox"
                                                menuPlacement="auto"
                                                options={getRoleList}
                                                value={selectedRole}
                                                onChange={(selectedOption) => {
                                                    setSelectedRole(selectedOption);
                                                    setRoleId(selectedOption ? selectedOption.value : "")
                                                    setExportFilterData(true)
                                                }}
                                                isClearable
                                            />
                                            <Label className="form-label" htmlFor="assignStatus">
                                                Store
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className='col mb-0'>
                                            <Select
                                                type="text"
                                                id="Role"
                                                name="Role"
                                                className="form-control selectbox"
                                                menuPlacement="auto"
                                                options={getRoleList}
                                                value={selectedRole}
                                                onChange={(selectedOption) => {
                                                    setSelectedRole(selectedOption);
                                                    setRoleId(selectedOption ? selectedOption.value : "")
                                                    setExportFilterData(true)
                                                }}
                                                isClearable
                                            />
                                            <Label className="form-label" htmlFor="assignStatus">
                                                Status
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className='col mb-0'>
                                            <Select
                                                type="text"
                                                id="Role"
                                                name="Role"
                                                className="form-control selectbox"
                                                menuPlacement="auto"
                                                options={getRoleList}
                                                value={selectedRole}
                                                onChange={(selectedOption) => {
                                                    setSelectedRole(selectedOption);
                                                    setRoleId(selectedOption ? selectedOption.value : "")
                                                    setExportFilterData(true)
                                                }}
                                                isClearable
                                            />
                                            <Label className="form-label" htmlFor="assignStatus">
                                                Category
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className='col mb-0'>
                                            <Select
                                                type="text"
                                                id="Role"
                                                name="Role"
                                                className="form-control selectbox"
                                                menuPlacement="auto"
                                                options={getRoleList}
                                                value={selectedRole}
                                                onChange={(selectedOption) => {
                                                    setSelectedRole(selectedOption);
                                                    setRoleId(selectedOption ? selectedOption.value : "")
                                                    setExportFilterData(true)
                                                }}
                                                isClearable
                                            />
                                            <Label className="form-label" htmlFor="assignStatus">
                                                Priority
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className='col mb-0'>
                                            <Select
                                                type="text"
                                                id="Role"
                                                name="Role"
                                                className="form-control selectbox"
                                                menuPlacement="auto"
                                                options={getRoleList}
                                                value={selectedRole}
                                                onChange={(selectedOption) => {
                                                    setSelectedRole(selectedOption);
                                                    setRoleId(selectedOption ? selectedOption.value : "")
                                                    setExportFilterData(true)
                                                }}
                                                isClearable
                                            />
                                            <Label className="form-label" htmlFor="assignStatus">
                                                Vendor
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className='col mb-0'>
                                            <ReactDatePicker
                                                placeholderText="Date"
                                                className="form-control"
                                            />
                                            <Label className='form-label'>Date</Label>
                                        </FormGroup>
                                    </Row>
                                    <Button
                                        className="my-tooltip cancel_filter"
                                        data-tooltip-content={"Close filter"}
                                        color="link"
                                        onClick={handelShowFIlter}
                                    >
                                        <CloseSvg />
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </>
                </CardBody>
            </Card>
        </>
    )
}
const mapStateToProps = ({ roleList }) => {
    let getRoleList = null;
    if (roleList?.getRoles?.list?.length > 0) {
        getRoleList = roleList.getRoles.list.map((role) => ({
            label: role.roleName,
            value: role.id,
        }));
    }

    return {
        getRoleList,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        RoleListRequest: () => {
            dispatch(getRoleListRequest());
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserFilter);