export const GET_MEMBER_LIST_REQUEST = "GET_MEMBER_LIST_REQUEST";
export const GET_MEMBER_LIST_SUCCESS = "GET_MEMBER_LIST_SUCCESS";

export const ROMOVE_STORE_GROUP_REQUEST = "ROMOVE_STORE_GROUP_REQUEST";
export const ROMOVE_STORE_GROUP_SUCCESS = "ROMOVE_STORE_GROUP_SUCCESS";

export const MEMBER_DATA_EXPORT_REQUEST = "MEMBER_DATA_EXPORT_REQUEST";
export const MEMBER_DATA_EXPORT_SUCCESS = "MEMBER_DATA_EXPORT_SUCCESS";

export const GET_MEMBER_HRA_LIST_REQUEST = "GET_MEMBER_HRA_LIST_REQUEST";
export const GET_MEMBER_HRA_LIST_SUCCESS = "GET_MEMBER_HRA_LIST_SUCCESS";

export const GET_SUB_MEMBER_HRA_LIST_REQUEST = "GET_SUB_MEMBER_HRA_LIST_REQUEST";
export const GET_SUB_MEMBER_HRA_LIST_SUCCESS = "GET_SUB_MEMBER_HRA_LIST_SUCCESS";

export const CREATE_MULTI_STORE_REQUEST = "CREATE_MULTI_STORE_REQUEST";
export const CREATE_MULTI_STORE_SUCCESS = "CREATE_MULTI_STORE_SUCCESS";

export const GET_MULTI_STORE_DETAILS_REQUEST = "GET_MULTI_STORE_DETAILS_REQUEST";
export const GET_MULTI_STORE_DETAILS_SUCCESS = "GET_MULTI_STORE_DETAILS_SUCCESS";

export const CLEAR_CREATE_MULTI_STORE_SUCCESS = "CLEAR_CREATE_MULTI_STORE_SUCCESS";

export const GET_MEMBER_DETAILS_REQUEST = "GET_MEMBER_DETAILS_REQUEST";
export const GET_MEMBER_DETAILS_SUCCESS = "GET_MEMBER_DETAILS_SUCCESS";

export const CREATE_UPLOAD_AGREEMENT_REQUEST = "CREATE_UPLOAD_AGREEMENT_REQUEST";
export const CREATE_UPLOAD_AGREEMENT_SUCCESS = "CREATE_UPLOAD_AGREEMENT_SUCCESS";

export const CLEAR_UPLOAD_AGREEMENT_SUCCESS = "CLEAR_UPLOAD_AGREEMENT_SUCCESS";

export const ZOHO_STORE_LIST_REQUEST = "ZOHO_STORE_LIST_REQUEST";
export const ZOHO_STORE_LIST_SUCCESS = "ZOHO_STORE_LIST_SUCCESS";
