import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_DASHBOARD_REQUEST } from "./type";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { handleApiCallException } from "../../shared/action";
import { getDashboardSuccess } from "./action";
import { invokeApi } from "../../../helper/axiosHelper";
import { DANGER } from "../../../constant/commonConstants";

function* getDashboard() {
    try {
        const response = yield call(invokeApi, "get", "/dashboard");
        if (response.status) {
            yield put(getDashboardSuccess(response.data));
        } else {
            yield put(getDashboardSuccess(response));
            ApiResponseMessage(response.message, DANGER);
            handleApiCallException(response.message);
        }
    } catch (error) {
        ApiResponseMessage(error, DANGER);
        handleApiCallException(error);
    }
}

function* watchDashboardRequest() {
    yield takeLatest(GET_DASHBOARD_REQUEST, getDashboard);
}

function* DashboardSaga() {
    yield all([fork(watchDashboardRequest)]);
}

export default DashboardSaga;
