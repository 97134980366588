import React, { useState } from "react";
import { exportDataExportRequest } from "../../../redux/exportData/action";
import { connect } from "react-redux";
import { Button, ButtonGroup, FormGroup, Input, Label } from "reactstrap";

function ExportData({
    exportDataExportRequest,
    dispatchData,
    exportFilterData,closeModal
}) {
    const [selectedData, setSelectedData] = useState("");
    const values = [
        {
            icon: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 21L1 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/><path d="M20 21V5C20 3.11438 20 2.17157 19.4143 1.58579C18.8285 1 17.8857 1 16 1H14C12.1144 1 11.1716 1 10.5858 1.58579C10.1143 2.05733 10.0223 2.76022 10.0044 4" stroke="currentColor" stroke-width="1.5"/><path d="M14 21V8C14 6.11438 14 5.17157 13.4142 4.58579C12.8284 4 11.8856 4 10 4H6C4.11438 4 3.17157 4 2.58579 4.58579C2 5.17157 2 6.11438 2 8V21" stroke="currentColor" stroke-width="1.5"/><path d="M8 21V18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/><path d="M5 7H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/><path d="M5 10H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/><path d="M5 13H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/></svg>,
            label: "Export With Group Store",
            value: "GROUP_STORE",
        },
        {
            icon: <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="6" r="2" stroke="currentColor" stroke-width="1.5"/><path d="M12 12C12 13.1046 12 14 8 14C4 14 4 13.1046 4 12C4 10.8954 5.79086 10 8 10C10.2091 10 12 10.8954 12 12Z" stroke="currentColor" stroke-width="1.5"/><path d="M1 9C1 5.22876 1 3.34315 2.17157 2.17157C3.34315 1 5.22876 1 9 1H13C16.7712 1 18.6569 1 19.8284 2.17157C21 3.34315 21 5.22876 21 9C21 12.7712 21 14.6569 19.8284 15.8284C18.6569 17 16.7712 17 13 17H9C5.22876 17 3.34315 17 2.17157 15.8284C1 14.6569 1 12.7712 1 9Z" stroke="currentColor" stroke-width="1.5"/><path d="M18 9H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/><path d="M18 6H13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/><path d="M18 12H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/></svg>,
            label: "Export As Member List",
            value: "MEMBER_LIST",
        },
    ];

    const handleRadioChange = (event) => {
        setSelectedData(event.target.value);
    };

    const handleSubmit = () => {
        exportDataExportRequest({
            type: "post",
            url: selectedData === "GROUP_STORE" ? "/memberStoreGroupDataExport" : "/memberDataExport",
            data: { ...dispatchData, isFilteredDataExport: exportFilterData },
        });
        closeModal()
    };

    return (
        <FormGroup tag="fieldset" className="export-data mb-0">
            <h6>Select Type</h6>
            <div className="export-options">
            {values.map((data) => {
                return (
                    <FormGroup check key={data.value}>
                        <Input
                            name={data.value}
                            type="radio"
                            id={data.value}
                            value={data.value}
                            checked={selectedData === data.value}
                            onChange={handleRadioChange}
                        />{" "}
                        <Label htmlFor={data.value} check>
                        {data.icon}{data.label}
                        </Label>
                    </FormGroup>
                );
            })}
            </div>
            <div className="d-flex align-items-center gap-2">
                <Button color="secondary" className="mr-2" onClick={closeModal} outline>Cancel</Button>
                <Button color="primary" className="ml-2" onClick={handleSubmit}>Export</Button>
            </div>
        </FormGroup>
    );
}
const mapStateToProps = ({ }) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        exportDataExportRequest: (data) => dispatch(exportDataExportRequest(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportData);
