import { Field, Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup, Label, Row } from "reactstrap";
import { __t } from "../../../i18n/translator";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { getRoleListRequest } from "../../../redux/admin/role/action";
import { connect } from "react-redux";
import { addUserListRequest } from "../../../redux/admin/user/action";
import { addUserInitialState } from "./CommonFunction";
import demoimg from "../../../assets/images/contac-us-bg.jpg";
import { CallSvg, EmailSvg2, LocationSvg, ShopSvg, UserSvg2, PaperclipSvg, DeleteSvg } from "../../../components/CommonSvg";

function SupportTicket(props) {
    const {
        onModalDismiss,
        RoleListRequest,
        dispatchData,
        SupportTicketStatus,
        SupportTicketRequest,
        getRoleList,
        setIsEdit,
    } = props;
    const IsEditData = props?.data?.id;
    const [closeModal, setCloseModal] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const [UserData] = useState(addUserInitialState(props?.data));

    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            SupportTicketRequest({ values, dispatchData });

            setCloseModal(true);
        } else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        RoleListRequest();
    }, [props.modalView === true]);
    useEffect(() => {
        if (closeModal === true) {
            onModalDismiss(false);
            setIsEdit(null);
            setCloseModal(false);
        }
    }, [SupportTicketStatus === true]);

    return (
        <>
            <Formik initialValues={UserData} onSubmit={handleSubmit}>
                {({ isSubmitting, values, setFieldValue }) => (
                    <>
                        <Form className="form-horizontal form-login">
                            <div className="category-head">
                                <FormGroup className=" mb-3">
                                    <Field
                                        type="Store"
                                        id="Store"
                                        name="Store"
                                        className="form-control selectbox"
                                        component={Select}
                                        menuPlacement="auto"
                                        options={getRoleList}
                                        value={getRoleList?.find(
                                            (option) => option.value === values.roleId
                                        )}
                                        onChange={(data) => {
                                            setFieldValue("roleId", data.value);
                                        }}

                                    />
                                    <Label className="form-label" htmlFor="Store">
                                        Select Store
                                        <span className="text-danger required">*</span>
                                    </Label>
                                </FormGroup>

                                <div className="support-request-modal-list">
                                    <div className="support-request-modal-data">
                                        <span className="support-icon-box">
                                            <UserSvg2 />
                                        </span>
                                        <p>Jey Patel</p>
                                    </div>
                                    <div className="support-request-modal-data">
                                        <span className="support-icon-box">
                                            <ShopSvg />
                                        </span>
                                        <p>Hometown Market</p>
                                    </div>
                                    <div className="support-request-modal-data">
                                        <span className="support-icon-box">
                                            <CallSvg />
                                        </span>
                                        <p>+91 9876543210</p>
                                    </div>
                                    <div className="support-request-modal-data">
                                        <span className="support-icon-box">
                                            <EmailSvg2 />
                                        </span>
                                        <p>jey.patel97@yopmail.com</p>
                                    </div>
                                    <div className="support-request-modal-data address">
                                        <span className="support-icon-box">
                                        <LocationSvg />
                                        </span>
                                        <p>306 Beersheba Highway, McMinnville, TN, 37110</p>
                                    </div>
                                </div>
                                <Row>
                                    <FormGroup className="col-12 mb-3">
                                        <Field
                                            type="subject"
                                            id="subject"
                                            name="subject"
                                            className="form-control"
                                            placeholder="Full Name"
                                        />
                                        <Label className="form-label" htmlFor="fullname">
                                            Subject
                                            <span className="text-danger required">*</span>
                                        </Label>
                                        {validator.current.message(
                                            __t("validations.user.subject"),
                                            values.subject,
                                            "required",
                                            {
                                                messages: {
                                                    required: __t("validations.user.subject"),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-4 mb-3">
                                        <Field
                                            type="category"
                                            id="category"
                                            name="category"
                                            className="form-control selectbox"
                                            component={Select}
                                            menuPlacement="auto"
                                            options={getRoleList}
                                            value={getRoleList?.find(
                                                (option) => option.value === values.roleId
                                            )}
                                            onChange={(data) => {
                                                setFieldValue("roleId", data.value);
                                            }}

                                        />
                                        <Label className="form-label" htmlFor="category">
                                            Category
                                            <span className="text-danger required">*</span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-4 mb-3">
                                        <Field
                                            type="priority"
                                            id="priority"
                                            name="priority"
                                            className="form-control selectbox"
                                            component={Select}
                                            menuPlacement="auto"
                                            options={getRoleList}
                                            value={getRoleList?.find(
                                                (option) => option.value === values.roleId
                                            )}
                                            onChange={(data) => {
                                                setFieldValue("roleId", data.value);
                                            }}

                                        />
                                        <Label className="form-label" htmlFor="priority">
                                            Priority
                                            <span className="text-danger required">*</span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-4 mb-3">
                                        <Field
                                            type="Vendor"
                                            id="Vendor"
                                            name="Vendor"
                                            className="form-control selectbox"
                                            component={Select}
                                            menuPlacement="auto"
                                            options={getRoleList}
                                            value={getRoleList?.find(
                                                (option) => option.value === values.roleId
                                            )}
                                            onChange={(data) => {
                                                setFieldValue("roleId", data.value);
                                            }}

                                        />
                                        <Label className="form-label" htmlFor="Vendor">
                                            Vendor
                                            <span className="text-danger required">*</span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-12 mb-3">
                                        <Field
                                        as="textarea"
                                        rows={3}
                                        name="description"
                                        placeholder="description.."
                                        className="form-control"
                                        maxLength="1050"
                                        />
                                        <Label className="form-label" htmlFor="description">Description</Label>
                                    </FormGroup>
                                    <FormGroup className="col-12 mb-2 file-attchment">
                                        <Field name="file"
                                            id="file"
                                            type="file"/>
                                        <Label for="file">
                                            <PaperclipSvg /> <span>Upload attachment<small>Max 20 MB allowed</small></span>
                                        </Label>
                                    </FormGroup>
                                    <ul className="col-12 uploaded-attachment">
                                        <li>
                                            <Button
                                            color="link"
                                            className="text-danger">
                                                    <DeleteSvg />
                                            </Button>
                                            <img src={demoimg} alt="Demo image" />
                                        </li>
                                    </ul>
                                </Row>
                            </div>

                            <div className="btn-box">
                                <Button
                                    color="primary"
                                    className=""
                                    title="user data"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {IsEditData ? "Update" : "Submit"}
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
const mapStateToProps = ({ userList, roleList }) => {
    let getRoleList = null;
    if (roleList?.getRoles?.list?.length > 0) {
        getRoleList = roleList.getRoles.list.map((role) => ({
            label: role.roleName,
            value: role.id,
        }));
    }

    return {
        getRoleList,
        SupportTicket: userList?.addUser && userList.addUser,
        SupportTicketStatus: userList?.addUser?.status && userList.addUser.status,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        RoleListRequest: () => {
            dispatch(getRoleListRequest());
        },
        SupportTicketRequest: (data) => {
            dispatch(addUserListRequest(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupportTicket);
