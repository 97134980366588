import React, { useState } from "react";
import { BackSvg, EditbtnSvg } from "../../../components/CommonSvg";
import { connect } from "react-redux";
import EditAccount from "./EditAccount";

function AccountInfo({
    profileDetail,
    hraMemberId,setActive,
    upadteProfileDetailRequest,
}) {
    const [isEditSec, setIsEditSec] = useState(false);
    return (
        <>
            {!isEditSec && (
                <div className="owner_view_section tab-card mb-4">
                    <div className="tab_content_head">
                        <div>
                            <button className="back_btn" title="back" onClick={() => setActive(true)}>
                                <BackSvg />
                            </button>
                            <h4>Owner Details</h4>
                        </div>
                        <div>
                            <button
                                className="btn btn-primary"
                                title="Edit"
                                onClick={() => setIsEditSec(true)}
                            >
                                <EditbtnSvg />
                                Edit
                            </button>
                        </div>
                    </div>
                    <div className="profile-field-list row">
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Owner First Name</label>
                                <span>{profileDetail?.firstName}</span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Owner Last Name</label>
                                <span>{profileDetail?.lastName}</span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Owner Email</label>
                                <span>{profileDetail?.emailAddress}</span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Owner Mobile Number</label>
                                <span>{profileDetail?.phoneNumberMobileNumber}</span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Status</label>
                                <span>{profileDetail?.status || "--"}</span>
                            </div>
                        </div>{" "}
                        <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                            <div className="hra-light-form-box">
                                <label>Date Enrolled</label>
                                <span>{profileDetail?.dateEnrolled || "--"}</span>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="hra-light-form-box">
                                <label>Mailing Address</label>
                                <span>
                                    {profileDetail?.mailingStreetAddress +
                                        ", " +
                                        profileDetail?.mailingAddressCity +
                                        ", " +
                                        profileDetail?.mailingState}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isEditSec && (
                <EditAccount
                    profileDetail={profileDetail}
                    upadteProfileDetailRequest={upadteProfileDetailRequest}
                    hraMemberId={hraMemberId}
                    setIsEditSec={setIsEditSec}
                />
            )}
            <div className="tab-card">
                <div className="tab_content_head">
                    <h4>Vendors Account Number</h4>
                </div>
                <div className="profile-field-list row">
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Coke Account #</label>
                            <span>{profileDetail?.unitedCokeAccountNumber || "--"}</span>
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Pepsi Account #</label>
                            <span>{profileDetail?.pepsicoAccountNumber || "--"}</span>
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Frito Lay Account #</label>
                            <span>{profileDetail?.fritoLayAccountNumber || "--"}</span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>HT Hackney Account #</label>
                            <span>{profileDetail?.htHackneyAccountNumber || "--"}</span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Eby Brown Account #</label>
                            <span>{profileDetail?.ebyBrownAccountNumber || "--"}</span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Core-mark Account Number #</label>
                            <span>{profileDetail?.coreMarkAccountNumber || "--"}</span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>ICW Account #</label>
                            <span>
                                {profileDetail?.iceCreamWarehouseAccountNumber || "--"}
                            </span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>UTZ/Golden Flake Account #</label>
                            <span>{profileDetail?.utzAccountNumber || "--"}</span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Red Bull Account #</label>
                            <span>{profileDetail?.redBullAccountNumber || "--"}</span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>McLane Account #</label>
                            <span>{profileDetail?.mclanecoAccountNumber || "--"}</span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Crystal Cubes Ice Account #</label>
                            <span>
                                {profileDetail?.crystalCubesIceAccount_Number || "--"}
                            </span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Community Coffee Account #</label>
                            <span>
                                {profileDetail?.coummunityCoffeeAccountNumber || "--"}
                            </span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Buffalo Rock Account #</label>
                            <span>{profileDetail?.buffaloRockAccountNumber || "--"}</span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Rapid XChange Account #</label>
                            <span>{profileDetail?.rapidXchangeAccountNumber || "--"}</span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Clark Gas Account #</label>
                            <span>{profileDetail?.clarkGasAccountNumber || "--"}</span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                        <div className="hra-light-form-box">
                            <label>Lynco Products Account #</label>
                            <span>{profileDetail?.lyncoAccountNumber || "--"}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({ profileData }) => {
    let profileDetail = profileData?.profileDetail?.details
        ? profileData.profileDetail.details
        : null;
    return {
        profileDetail: profileDetail,
    };
};
export default connect(mapStateToProps, null)(AccountInfo);
