import { Field, Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup, Input, Label, Row } from "reactstrap";
import { __t } from "../../../i18n/translator";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { getRoleListRequest } from "../../../redux/admin/role/action";
import { connect } from "react-redux";
import { addUserListRequest } from "../../../redux/admin/user/action";
import { addUserInitialState } from "./CommonFunction";
import demoimg from "../../../assets/images/contac-us-bg.jpg";
import { CallSvg, EmailSvg2, LocationSvg, ShopSvg, UserSvg2, PaperclipSvg, DeleteSvg } from "../../../components/CommonSvg";

function Settings(props) {
    const {
        onModalDismiss,
        RoleListRequest,
        dispatchData,
        SupportTicketStatus,
        SupportTicketRequest,
        getRoleList,
        setIsEdit,
    } = props;
    const IsEditData = props?.data?.id;
    const [closeModal, setCloseModal] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const [UserData] = useState(addUserInitialState(props?.data));

    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            SupportTicketRequest({ values, dispatchData });

            setCloseModal(true);
        } else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        RoleListRequest();
    }, [props.modalView === true]);
    useEffect(() => {
        if (closeModal === true) {
            onModalDismiss(false);
            setIsEdit(null);
            setCloseModal(false);
        }
    }, [SupportTicketStatus === true]);

    return (
        <>
            <Formik initialValues={UserData} onSubmit={handleSubmit}>
                {({ isSubmitting, values, setFieldValue }) => (
                    <>
                        <Form className="form-horizontal column-configuration">
                          <div className="checkbox-column">
                            <FormGroup>
                              <Input type="checkbox" id="tnumber" />
                              <Label for="tnumber">
                                Ticket number
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Input type="checkbox" id="towner" />
                              <Label for="towner">
                                Ticket Owner
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Input type="checkbox" id="status" />
                              <Label for="status">
                                Status
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Input type="checkbox" id="vendor" />
                              <Label for="vendor">
                                Vendor
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Input type="checkbox" id="category" />
                              <Label for="category">
                                Category
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Input type="checkbox" id="priority" />
                              <Label for="priority">
                                Priority
                              </Label>
                            </FormGroup>
                          </div>
                            <div className="btn-box">
                                <Button
                                    color="primary"
                                    className=""
                                    title="user data"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {IsEditData ? "Update" : "Submit"}
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
const mapStateToProps = ({ userList, roleList }) => {
    let getRoleList = null;
    if (roleList?.getRoles?.list?.length > 0) {
        getRoleList = roleList.getRoles.list.map((role) => ({
            label: role.roleName,
            value: role.id,
        }));
    }

    return {
        getRoleList,
        SupportTicket: userList?.addUser && userList.addUser,
        SupportTicketStatus: userList?.addUser?.status && userList.addUser.status,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        RoleListRequest: () => {
            dispatch(getRoleListRequest());
        },
        SupportTicketRequest: (data) => {
            dispatch(addUserListRequest(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
