import * as type from "./type";

export const getDashboardRequest = () => {
    return {
        type: type.GET_DASHBOARD_REQUEST,
    };
};
export const getDashboardSuccess = (data) => {
    return {
        type: type.GET_DASHBOARD_SUCCESS,
        payload: data
    };
};