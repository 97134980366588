export const optionVendor = [
    { value: 'Aitr Serv', label: 'Aitr Serv' },
    { value: 'Alani', label: 'Alani' },
    { value: 'ATM Works', label: 'ATM Works' },
    { value: 'BBG', label: 'BBG' },
    { value: 'Biolyte', label: 'Biolyte' },
    { value: 'BTC', label: 'BTC' },
    { value: 'Bucked Up Energy', label: 'Bucked Up Energy' },
    { value: 'Buffalo Rocks', label: 'Buffalo Rocks' },
    { value: 'Caribbean Creme', label: 'Caribbean Creme' },
    { value: 'Coca-Cola Consolidated', label: 'Coca-Cola Consolidated' },
    { value: 'Community Coffee', label: 'Community Coffee' },
    { value: 'Core-Marks', label: 'Core-Marks' },
    { value: 'Crystal Cubes', label: 'Crystal Cubes' },
    { value: 'Decatur Coca-Cola', label: 'Decatur Coca-Cola' },
    { value: 'Dippin Dots', label: 'Dippin Dots' },
    { value: 'Eternal', label: 'Eternal' },
    { value: 'Evamor', label: 'Evamor' },
    { value: 'Fiji', label: 'Fiji' },
    { value: 'Frito Lay', label: 'Frito Lay' },
    { value: 'Glacier Point', label: 'Glacier Point' },
    { value: 'HRA Accounting', label: 'HRA Accounting' },
    { value: 'H.T. Hackney', label: 'H.T. Hackney' },
    { value: 'ICW', label: 'ICW' },
    { value: 'Insurance Wala', label: 'Insurance Wala' },
    { value: 'Light on the Hill Coffee', label: 'Light on the Hill Coffee' },
    { value: 'Lynco', label: 'Lynco' },
    { value: 'MsLane', label: 'MsLane' },
    { value: 'Marquez', label: 'Marquez' },
    { value: 'Meco', label: 'Meco' },
    { value: 'Monster Energy', label: 'Monster Energy' },
    { value: 'ModiSoft', label: 'ModiSoft' },
    { value: 'Most Edge', label: 'Most Edge' },
    { value: 'Pepsi', label: 'Pepsi' },
    { value: 'PFG', label: 'PFG' },
    { value: 'Prime Hydration', label: 'Prime Hydration' },
    { value: 'Prime Energy', label: 'Prime Energy' },
    { value: 'Rapit Xchange', label: 'Rapit Xchange' },
    { value: 'Redbull', label: 'Redbull' },
    { value: 'Reyes Coca-Cola', label: 'Reyes Coca-Cola' },
    { value: 'Shop USA', label: 'Shop USA' },
    { value: 'SmartBizPay', label: 'SmartBizPay' },
    { value: 'Stewart', label: 'Stewart' },
    { value: 'TapOut', label: 'TapOut' },
    { value: 'Tennessee Valley Ice', label: 'Tennessee Valley Ice' },
    { value: 'True North', label: 'True North' },
    { value: 'Uptime', label: 'Uptime' },
    { value: 'UTZ', label: 'UTZ' },
    { value: 'W.L. Petrey', label: 'W.L. Petrey' },
    { value: 'ZOA', label: 'ZOA' },
];
export const optionsIsuue = [
    { value: 'Accounting', label: 'Accounting' },
    { value: 'Corporation change', label: 'Corporation change' },
    { value: 'Equipment', label: 'Equipment' },
    { value: 'FEIN change', label: 'FEIN change' },
    { value: 'Link', label: 'Link' },
    { value: 'Needs Service', label: 'Needs Service' },
    { value: 'New Service', label: 'New Service' },
    { value: 'Other', label: 'Other' },
    { value: 'Ownership change', label: 'Ownership change' },
    { value: 'Planogram', label: 'Planogram' },
    { value: 'Promotions', label: 'Promotions' },
    { value: 'Rebate', label: 'Rebate' },
    { value: 'Rebates', label: 'Rebates' },
    { value: 'Reset', label: 'Reset' },
    { value: 'Vendor or HRA', label: 'Vendor or HRA' },
];

export const optionsPriority = [
    { value: 'None', label: 'None', color: '#000000', background: '#00000033', },
    { value: 'Highest', label: 'Highest', color: '#DE002E', background: '#de002e40', },
    { value: 'High', label: 'High', color: '#C24200', background: '#c2420040', },
    { value: 'Medium', label: 'Medium', color: '#f0a300', background: '#f8b42533', },
    { value: 'Low', label: 'Low', color: '#00A7B5', background: '#00a7b533', },
    { value: 'When possible', label: 'When possible', color: '#83ce00', background: '#9bd73226', }
];