import { Form, Field, Formik } from "formik";
import React, { useRef, useState } from "react";
import { FormGroup, Label } from "reactstrap";
import { BackSvg } from "../../../components/CommonSvg";
import { __t } from "../../../i18n/translator";
import SimpleReactValidator from "simple-react-validator";

export default function EditAccount({ profileDetail, hraMemberId, setIsEditSec, upadteProfileDetailRequest }) {
    const validator = useRef(new SimpleReactValidator());

    const [profileData] = useState({
        phoneNumberMobileNumber: profileDetail?.phoneNumberMobileNumber ? profileDetail.phoneNumberMobileNumber : "",
        emailAddress: profileDetail?.emailAddress ? profileDetail.emailAddress : "",

        hraMemberId: hraMemberId
    });
    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            upadteProfileDetailRequest({ values: values, hraMemberId: hraMemberId })
            setIsEditSec(false)
        } else {
            validator.current.showMessages();
        }
    };
    return (
        <>
            {" "}
            <div className="owner_edit_section tab-card mb-4">
                <Formik initialValues={profileData} onSubmit={handleSubmit}>
                    {({ values }) => (
                        <>
                            <Form>
                                <div className="tab_content_head">
                                    <div>
                                        <button className="back_btn" title="back">
                                            <BackSvg />
                                        </button>
                                        <h4>Owner Details</h4>
                                    </div>
                                    <div className="d-flex gap-3">
                                        <button
                                            className="btn btn-secondary"
                                            title="cancel"
                                            onClick={() => setIsEditSec(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn btn-primary"
                                            type="submit"
                                            title="Save"

                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                                <div className="profile-field-list row">
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <div className="hra-light-form-box">
                                            <FormGroup className="m-0">
                                                <Field
                                                    id="phoneNumberMobileNumber"
                                                    name="phoneNumberMobileNumber"
                                                    placeholder="Member Phone"
                                                    className="form-control"
                                                    type="text"
                                                    maxLength={10}
                                                    minLength={10}
                                                />
                                                <Label
                                                    className="form-label"
                                                    htmlFor="phoneNumberMobileNumber"
                                                >
                                                    Member Phone
                                                    <span className="text-danger required">*</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.profile.phoneNumberMobileNumber"),
                                                    values.phoneNumberMobileNumber,
                                                    "required|matches:^[0-9]{10}$|numeric",
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                "validations.profile.phoneNumberMobileNumber"
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <div className="hra-light-form-box">
                                            <FormGroup className="m-0">
                                                <Field
                                                    id="emailAddress"
                                                    name="emailAddress"
                                                    placeholder="Member Email"
                                                    className="form-control"
                                                    type="email"
                                                />
                                                <Label className="form-label" htmlFor="emailAddress">
                                                    Member Email
                                                    <span className="text-danger required">*</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.profile.emailAddress"),
                                                    values.emailAddress,
                                                    "required",
                                                    {
                                                        messages: {
                                                            required: __t("validations.profile.emailAddress"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </>
    );
}
