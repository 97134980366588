import React, { useEffect, useState } from "react";
import TableHeader from "../../../components/Common/TableView/TableHeader";
import { RoleWiseModule, isImage, isPDF } from "../../../utils/generalUtils";
import {
  AdminAccess,
  MarketingAccess,
} from "../../../constant/commonConstants";
import { connect } from "react-redux";
import {
  deleteNewsLetterRequest,
  newsLetterRequest,
} from "../../../redux/admin/newsletter/action";

import { columns } from "./columns";
import { deleteInquiryRequest } from "../../../redux/admin/inquiry/action";
import { Date_Svg, EyeSvg, PdfSvg } from "../../../components/CommonSvg";
import { Card, CardBody } from "reactstrap";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import CommonPagination from "../../../components/Common/TableView/CommonPagination";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { formatDateToMMDDYYYY } from "../../../helper/DataTimeHelpers";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import AssignNewsletter from "./AssignNewsletter";

function NewsLatter({
  newsLetterRequest,
  NewsList,
  TotalNewsLetter,
  deleteNewsLetterRequest,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [endDate, setEndDate] = useState("");
  const [newsLetterId, setNewsLetterId] = useState([]);
  const [editPromationData, setEditPromationData] = useState([]);
  const [promationData, setPromationData] = useState([]);
  const [isAssigned, setIsAssigned] = useState("");
  const dispatchData = {
    searchText: searchData ? searchData : "",
    startDate: startDate ? formatDateToMMDDYYYY(startDate) : "",
    endDate: endDate ? formatDateToMMDDYYYY(endDate) : "",
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: "createdDate",
      orderBy: "ASC",
    },
  };

  useEffect(() => {
    newsLetterRequest(dispatchData);
  }, [dataPerPage, currentPage, searchData, startDate, endDate]);
  const [modalView, setModaleView] = useState(false);
  const onModalDismiss = () => {
    setModaleView(false);
    setNewsLetterId([]);
    setModaleView(false);
    setEditPromationData([]);
    setPromationData([]);
  };
  const HandelSelectAssign = (data) => {
    if (data.isAssigned !== true) {
      if (newsLetterId.includes(data.id)) {
        const updatedPromationData = promationData.filter(
          (item) => item.id !== data.id
        );
        const updatedPromationId = newsLetterId.filter((id) => id !== data.id);
        setPromationData(updatedPromationData);
        setNewsLetterId(updatedPromationId);
      } else {
        setPromationData([
          ...promationData,
          {
            imgUrl: data.documentPath,
            id: data.id,
            title: data.title,
            description: data.description,
            assignUser: data.createdBy,
          },
        ]);
        setNewsLetterId([...newsLetterId, data.id]);
      }
    }
  };
  return (
    <>
      <div className="container">
        <TableHeader
          hedingName={"Newsletter"}
          linkUrl={RoleWiseModule(AdminAccess) && "/admin/newsletter/add"}
          buttonName={RoleWiseModule(AdminAccess) && "Add Newsletter"}
          searchBar={true}
          Filter={true}
          setSearchData={setSearchData}
          filterType={"newsletter"}
          updateData={() => {
            newsLetterRequest({
              searchText: "",
              startDate: "",
              endDate: "",
              pagination: {
                limit: 10,
                page: 1,
                orderKey: "createdDate",
                orderBy: "ASC",
              },
            });
          }}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          endDate={endDate}
          startDate={startDate}
          //   handelShowFIlter={handelShowFIlter}
        />
        <ul className="Promotion_section newsletter_section">
          {NewsList?.length > 0 &&
            NewsList.map((data) => {
              return (
                <li>
                  <Card
                    className={`promotion_card ${
                      newsLetterId.includes(data.id) ? "selected" : ""
                    }`}
                  >
                    <CardBody>
                      <div className="card_img">
                        {/* {isImage(data.documentPath) ? (
                           <div className="promotion_img">
                            <img width="80" src={data.documentPath} />
                          </div>
                        ) : (
                            <div className="promotion_svg">
                              <span className="docfile">
                                <PdfSvg />
                              </span>
                            </div>
                        )} */}
                        {data.thumbnailPath ? (
                          <div className="promotion_img">
                            <img width="80" src={data.thumbnailPath} />
                          </div>
                        ) : isImage(data.documentPath) ? (
                          <div className="promotion_img">
                            <img width="80" src={data.documentPath} />
                          </div>
                        ) : (
                          <div className="promotion_svg">
                            <span className="docfile">
                              <PdfSvg />
                            </span>
                          </div>
                        )}
                        <Link
                          title="Click for item detail"
                          className="view_more"
                          to={"/admin/newsletter/" + data.id}
                          state={data}
                        >
                          <EyeSvg />
                        </Link>
                      </div>
                      <div
                        className={
                          data.isAssigned === true
                            ? "card_content"
                            : "card_content cursor-auto"
                        }
                        title={
                          !data.isAssigned &&
                          newsLetterId.length === 0 &&
                          "Double-click for the multiple select items."
                        }
                        onDoubleClick={() => {
                          if (newsLetterId.length === 0) {
                            HandelSelectAssign(data);
                          }
                        }}
                        onClick={() => {
                          if (newsLetterId.length >= 1) {
                            HandelSelectAssign(data);
                          }
                        }}
                      >
                        <div className="card_content_text">
                          <div className="promotion_title">
                            <h4>{data.title} </h4>
                            <div className="d-flex justify-content-end">
                              <Switch
                                className={`react-switch justify-content-center switch-sm ${
                                  data.isActive && "isActive"
                                }`}
                                type="checkbox"
                                id="custom-switch"
                                checked={data.isActive}
                                onChange={async () => {
                                  const response = await commonConfirmBox(
                                    data.isActive
                                      ? "Do you want to deactivate this Newsletter?"
                                      : "Do you want to activate this Newsletter?",
                                    undefined,
                                    undefined
                                  );
                                  if (response) {
                                    deleteNewsLetterRequest({
                                      newsletterId: data.id,
                                      isActive: !data.isActive,
                                      dispatchData,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <p>{data.description} </p>
                        </div>
                        <div className="d-flex justify-content-between align-center align-items-center px-2 pt-2 pb-2">
                          <div className="card_date">
                            <Date_Svg />
                            <p>{data.startDate}</p>
                            <b>to</b>
                            <p>{data.endDate}</p>
                          </div>
                          <button
                            className={
                              data?.isAssigned === true
                                ? "creat_btn assigned text-white"
                                : "creat_btn text-white"
                            }
                            title="Assign Newsletter"
                            onClick={() => {
                              const havePromationId = newsLetterId.includes(
                                data.id
                              );
                              if (!havePromationId) {
                                setModaleView(true);
                                setNewsLetterId([...newsLetterId, data.id]);
                                setIsAssigned(data.isAssigned);
                                setEditPromationData(data.AllocateNewsletters);
                                setPromationData([
                                  ...promationData,
                                  {
                                    imgUrl: data.documentPath,
                                    id: data.id,
                                    title: data.title,
                                    assignUser: data.createdBy,
                                  },
                                ]);
                              } else {
                                setModaleView(true);
                              }
                            }}
                          >
                            {data.isAssigned === true ? "Assigned" : "Assign"}
                          </button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </li>
              );
            })}
        </ul>
        {TotalNewsLetter > 0 ? (
          <CommonPagination
            dataPerPage={dataPerPage}
            totalData={TotalNewsLetter}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setDataPerPage={setDataPerPage}
          />
        ) : (
          <h4 className="text-center mt-5 mb-5">No Data Found</h4>
        )}
      </div>
      {modalView && (
        <CustomisedModal
          modalView={modalView}
          customClass={
            newsLetterId.length > 1
              ? "category-modal modal-lg"
              : "category-modal "
          }
          modalName={"Assign Newsletter"}
          onModalDismiss={onModalDismiss}
        >
          <AssignNewsletter
            onModalDismiss={onModalDismiss}
            isAssigned={isAssigned}
            setPromationData={setPromationData}
            editPromationData={isAssigned === true ? editPromationData : []}
            setEditPromationData={setEditPromationData}
            promationData={promationData}
            //   AssignPromotionStatus={AssignPromotionStatus}
            newsLetterId={newsLetterId}
            AssignNewsletterId={newsLetterId}
            dispatchData={dispatchData}
          />
        </CustomisedModal>
      )}
    </>
  );
}
const mapStateToProps = ({ NewsLetter }) => {
  let NewsList =
    NewsLetter.newsLetter?.list?.length > 0 ? NewsLetter.newsLetter.list : [];
  let TotalNewsLetter = NewsLetter?.newsLetter?.totalRecords
    ? NewsLetter.newsLetter.totalRecords
    : null;
  return {
    NewsList,
    TotalNewsLetter,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteNewsLetterRequest: (data) => dispatch(deleteNewsLetterRequest(data)),
    newsLetterRequest: (data) => dispatch(newsLetterRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsLatter);
