import * as type from "./type" 
const settingRequest = (data)=> {
    return {
        type: type.SETTING_REQUEST,
        payload: data
    }
}
const settingSuccess = (data)=> {
    return {
        type: type.SETTING_SUCCESS,
        payload: data
    }
}
export {settingRequest , settingSuccess}